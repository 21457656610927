@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $black;
	font-family: $primary-font;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
	font-family: $italic-font;
	background-color: $black;
}

a {
	color: inherit;

	@include hover-focus {
		color: $grey;
	}
}

h1 {
	font-weight: 400;
	font-family: $italic-font;
}

// Global Classes
.italic {
	font-style: italic;
}

.btn {
	display: inline-block;
	background-color: $white;
	color: $black;
	border-radius: rems(21);
	margin: auto 0;
	padding: rems(12) rems(45);
	font-family: $primary-font;
	text-align: center;
	width: rems(230);

	@include hover-focus {
		color: $black;
		background-color: $grey;
		// border: 1px solid $white;
	}
}

header {
	background-color: $black;
	background-image: url(../img/bg/light-rays.png), url(../img/bg/hero.png);
	background-position: center, right top;
	background-size: cover, contain;
	background-repeat: no-repeat;
	position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	// @media screen and (max-width:1000px) {
	// 	margin-bottom: rems(380);
	// }

	@media screen and (max-width: 700px) {
		height: 152vw;
	}

	#navbar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: transparent;
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		@media screen and (max-width: 1100px) {
			height: 100%;
		}

		.container {
			display: flex;
			max-width: rems(1500);

			@media screen and (max-width: 1100px) {
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
			}

			.social {
				display: flex;
				gap: rems(16);
				font-size: rems(18);
				padding: rems(30);
				opacity: 0.5;

				@media screen and (max-width: 1100px) {
					padding: 0;
					font-size: rems(30);
					position: absolute;
					top: rems(-10);
					right: rems(20);
				}

				li {
					list-style: none;
					transition: all 0.3s ease-in-out;
					text-align: center;

					@media screen and (max-width: 700px) {
						font-weight: 100;
					}
				}

				.subscribe-icon {
					@media screen and (min-width: 1101px) {
						display: none;
					}
				}
			}

			.btn {
				font-size: rems(14);
				width: rems(160);

				@media screen and (max-width: 1100px) {
					display: none;
				}
			}
		}
	}

	.wrap {
		transform: translateY(60px);

		.tagline {
			max-width: rems(500);
			display: flex;
			flex-direction: column;
			align-items: center;

			@media screen and (max-width: 1000px) {
				display: none;
			}

			h1 {
				font-size: rems(61);

				span {
					color: $grey;
				}
			}

			p {
				font-size: rems(20);
				line-height: 2em;
			}

			a {
				font-size: rems(20);
				margin-top: rems(40);
			}
		}

		p {

			span {}
		}
	}
}

main {
	padding: rems(160) 0 rems(100);

	@media screen and (max-width: 999px) {
		padding: rems(60) 0 rems(100);
	}

	.mobile-tagline {
		display: none;

		@media screen and (max-width: 999px) {
			display: block;
			padding-bottom: rems(100);
			text-align: center;
		}

		.container {
			display: flex;
			align-items: center;
			flex-direction: column;

			h1 {
				font-size: rems(61);

				span {
					color: $grey;
				}
			}

			p {
				font-size: rems(20);
				line-height: 2em;
			}

			a {
				display: block;
				font-size: rems(20);
				margin-top: rems(40);
			}
		}
	}


	#preorder {

		.container {
			display: flex;
			// justify-content: space-around;
			gap: rems(126);

			@media screen and (max-width: 1100px) {
				flex-direction: column;
			}

			.split {
				text-align: center;
				display: flex;
				flex-direction: column;
				align-content: center;

				@media screen and (max-width: 1100px) {
					padding-bottom: rems(100);
				}

				img {
					max-width: rems(533);
					margin-bottom: rems(45);
					align-self: center;
				}

				.info {
					max-width: rems(500);
					align-self: center;
					line-height: 1.1em;
				}

				h2 {
					color: $grey;
					margin: 0 0 rems(16);
					font-size: rems(35);
				}

				h3 {
					margin: 0;
					margin: 0 0 rems(24);
					font-size: rems(25);
				}

				p {
					font-size: rems(18);
					font-family: $primary-font;
					padding-bottom: rems(20);
				}

				.fade-text {
					opacity: 0.5;
					padding-bottom: rems(20);
				}

				.btn {
					align-self: center;
				}
			}

			.left {}

			.right {}
		}
	}

	#video {
		padding-top: rems(220);

		@media screen and (max-width: 1100px) {
			padding-top: rems(50);
		}
	}
}

footer {
	font-family: $primary-font;

	.container {
		text-align: center;

		.social {
			display: flex;
			gap: rems(16);
			font-size: rems(18);
			padding: rems(30);
			justify-content: center;

			@media screen and (max-width: 1100px) {}

			li {
				list-style: none;
				transition: all 0.3s ease-in-out;
				text-align: center;
			}

			a {
				font-size: rems(16);

				@media screen and (max-width: 1100px) {
					font-size: rems(42);
				}
			}
		}

		.btn {
			margin-bottom: rems(53);
			font-size: rems(20);
			width: rems(320);
		}
	}
}