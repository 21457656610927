$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #141511;
$grey: #AEAEAE;

@font-face {
  font-family: 'DeVinne Txt BT';
  src: url('../font/DeVinneBT-ItalicText.woff2') format('woff2'),
      url('../font/DeVinneBT-ItalicText.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DeVinne Txt';
  src: url('../font/De-Vinne-Text-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$italic-font: "DeVinne Txt BT", Arial, serif;
$primary-font: "DeVinne Txt", Arial, sans-serif;